import { Formik, Field, Form, FormikHelpers, ErrorMessage } from 'formik';
import LoginFormValues from '../../interfaces/forms/LoginFormValues';
import { login } from '../../services/AuthService';
import { LoginSchema } from '../../validationSchemas/LoginSchema';
import { useState } from 'react';
import { InputField } from '../forms/InputField';
import {useNavigate} from 'react-router-dom';

export const LoginForm = () => {    
    const [loginBtnDisabled, setLoginBtnDisabled] = useState(false);
    const navigate = useNavigate();
    const initialValues: LoginFormValues = { username: '', password: '' };
    const onLogin = async (values: LoginFormValues, {setSubmitting}: FormikHelpers<LoginFormValues>) => {
        setLoginBtnDisabled(true);
        try {
            const {message, data} = await login(values.username, values.password);
            navigate('/home');
        } catch (error) {
            console.error("Error:", error);
        }
        setSubmitting(false);
        setLoginBtnDisabled(false);
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={LoginSchema}
            onSubmit={onLogin}
        >
            <Form>
                <InputField name="username" placeholder="Usuario*" type="text" />
                <InputField name="password" placeholder="Contraseña*" type="password" />
                <div className="text-center text-white mb-16">
                    <a href="">¿Olvidaste tu Contraseña?</a>
                </div>
                <div className="flex justify-center">
                    <button disabled={loginBtnDisabled} type="submit" className="w-80 h-10 rounded-lg bg-blue-800 text-white">Ingresar</button>
                </div>
            </Form>
        </Formik>
    )
}