import { useEffect, useRef } from "react";
import SignaturePad from 'signature_pad';

interface SignatureCanvasProps {
    minWidth?: number;
    maxWidth?: number;
    penColor?: string;
    onImageChange: (base64: string) => void;
}

const SignatureCanvas = ({ minWidth = 1, maxWidth = 2, penColor = 'black', onImageChange }: SignatureCanvasProps) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const padRef = useRef<SignaturePad | null>(null);
    const historyRef = useRef<string[]>([]);

    useEffect(() => {
        const canvas = canvasRef.current;

        if (canvas) {
            const pad = new SignaturePad(canvas, {
                minWidth,
                maxWidth,
                penColor
            });

            padRef.current = pad;
            
            return () => {
                padRef.current?.clear(); 
            };
        } else {
            console.error("canvasRef.current es null");
        }
    }, [minWidth, maxWidth, penColor]);

    const handleSignatureChange = () => {
        const pad = padRef.current;
        if (canvasRef.current && pad) {
            const dataURL = pad.toDataURL();
            onImageChange(dataURL);
        }
    };

    const clearCanvas = () => {
        const pad = padRef.current;
        if (pad) {
            pad.clear();
            onImageChange("");
        }
    };

    const undoSignature = () => {
        const pad = padRef.current;
        const history = historyRef.current;
        
        if (pad && history.length > 0) {
            const previousState = history.pop();
            if (previousState) {
                pad.clear();
                pad.fromDataURL(previousState);
                onImageChange(previousState);
            }
        } else {
            if (pad) {
                pad.clear();
            }
            onImageChange("");
        }
    };
    
    return (
        <div>
            <canvas
                ref={canvasRef}
                id="signatureCanvas"
                className="w-screen rounded-md border border-black"
                onClick={handleSignatureChange}
            ></canvas>
            <div className="py-4">
                <button className="rounded-md bg-gray-600 text-white border border-black px-4" onClick={clearCanvas}>Limpiar</button>
                {" "}
                <button className="rounded-md bg-gray-600 text-white border border-black px-4" onClick={undoSignature}>Deshacer</button>
            </div>
        </div>
    );
};

export default SignatureCanvas;
