import React, { useState, useEffect } from "react"
//import { Link } from "react-router-dom"
import { ContractList } from "../components/contracts/ContractList"
import { getContractsHistory } from "../services/ContractsService"

export const ContractHistory = () => {
    const [contracts, setContracts] = useState([])

    useEffect(() => {
        const getContracts = async () => {
            const data = await getContractsHistory();
            setContracts(data);
        }

        getContracts();
    }, [])

    return (
        <div>
            <div className="flex flex-row h-full text-center text-white mb-4">
                <div className="m-auto">Historial de Contratos</div>
            </div>

            <div className="text-center">
                <input type="text" placeholder="Buscar por referencia/cliente/estatus" className="w-80 h-10 rounded-lg px-4 bg-gray-100 text-gray-800 mb-8" />
            </div>

            <div className="h-screen bg-white p-4">
                <ContractList items={contracts} />
            </div>
        </div>
    )
}