import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { ContractList } from "../components/contracts/ContractList"
import { getAllContracts } from "../services/ContractsService"

export const Contracts = () => {
    const [contracts, setContracts] = useState([])

    useEffect(() => {
        const getContracts = async () => {
            const data = await getAllContracts();
            setContracts(data);
        }

        getContracts();
    }, [])

    return (
        <div>
            <div className="flex h-full text-white mb-4">
                <div className="flex flex-grow justify-center">Operaciones</div>
            </div>

            <div className="flex h-full text-white mb-4 justify-end px-4">
                <Link to="/create-contract" className="py-2 px-4">Agregar</Link>
            </div>

            <div className="text-center">
                <input type="text" placeholder="Buscar por referencia/cliente/estatus" className="w-80 h-10 rounded-lg px-4 bg-gray-100 text-gray-800 mb-8" />
            </div>

            <div className="h-screen bg-white p-4">
                <ContractList items={contracts} />
            </div>
        </div>
    )
}