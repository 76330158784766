import { Field, ErrorMessage } from 'formik';

interface InputFieldProps {
    name: string;
    placeholder: string;
    type: string;
}

export const InputField: React.FC<InputFieldProps> = ({ name, placeholder, type }) => (
    <div className="mb-8">
        <Field type={type} name={name} placeholder={placeholder} className="w-80 h-10 rounded-lg px-4 bg-gray-200 text-gray-800" />
        <ErrorMessage name={name} component="div" className='text-red-700' />
    </div>
);