import api from './ApiService';
import CreateContractValues from '../interfaces/forms/CreateContractValues';
import ContractEvidence from '../interfaces/forms/ContractEvidence';

export const getPendingContracts = async () => {
    try {
        // TODO: createdById should be taken from the logged in user
        const params = { status: 0 };
        const response = await api.get('/Contract', {params});
        return response.data;
    } catch (error) {
        console.error("Error:", error);
        throw error;
    }
}

export const getAllContracts = async () => {
    try {
        const response = await api.get('/Contract');
        return response.data;
    } catch (error) {
        console.error("Error:", error);
        throw error;
    }
}

export const getContractsHistory = async () => {
    try {
        // TODO: createdById should be taken from the logged in user
        const params = { createdById: 1 };
        const response = await api.get('/Contract', {params});
        return response.data;
    } catch (error) {
        console.error("Error:", error);
        throw error;
    }
}

export const getContractById = async (id: number) => {
    try {
        const response = await api.get(`/Contract/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error:", error);
        throw error;
    }
}

export const updateContractById = async (id: number, contract: CreateContractValues, evidence: ContractEvidence) => {
    try {
        const emailData = {
            Contract: contract,
            ContractEvidence: evidence,
            Format: "formato2"
        };

        const response = await api.put(`/Contract/Update/${id}`, emailData);
        return response.data;
    } catch(error){
        console.error("Error", error);
        throw error;
    }
}


export const createContract = async (contract: CreateContractValues) => {
    
    try {
        contract.CreatedById = 1;
        const response = await api.post('/Contract', contract);
        const createdContract = response.data;

        return createdContract;
    } catch (error) {
        console.error("Error:", error);
        throw error;
    }
}

const sendEmail = async (contractData: any) => {
    try {
        const response = await api.post('/api/Contract/SendEmail', contractData);
        return response.data;
    } catch (error) {
        console.error("Error sending email:", error);
        throw error;
    }
}