import './App.css';
import { Login } from './pages/Login';
import { Home } from './pages/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Layout } from './components/shared/Layout';
import { CreateContract } from './pages/CreateContract';
import { Contracts } from './pages/Contracts';
import { ContractDetail } from './pages/ContractDetail';
import { ContractHistory } from './pages/ContractHistory';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/contracts" element={<Layout><Contracts /></Layout>} />
        <Route path="/contract-history" element={<Layout><ContractHistory /></Layout>} />
        <Route path="/create-contract" element={<Layout><CreateContract /></Layout>} />
        <Route path="/contract-detail/:id" element={<Layout><ContractDetail /></Layout>} />
        <Route path="/home" element={<Layout><Home /></Layout>} />
        <Route path="/" element={<Layout><Home /></Layout>} />
      </Routes>
    </Router>
  );
}

export default App;