import { Link, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { getContractById, updateContractById } from "../services/ContractsService";
import Contract from "../interfaces/models/Contract";
import { FaArrowLeft, FaReceipt, FaUser } from "react-icons/fa";
import SignatureCanvas from "../components/signature/SignaturePad";
import CreateContractValues from "../interfaces/forms/CreateContractValues";
import ContractEvidence from "../interfaces/forms/ContractEvidence";

export const ContractDetail = () => {
    const [contract, setContract] = useState<Contract | null>(null);
    const [imageBase64, setImageBase64] = useState<string>("");
    const { id } = useParams<{ id: string }>();
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const signaturePadRef = useRef<any>(null); 
    const [signatureBase64, setSignatureBase64] = useState<string>("");


    useEffect(() => {
        const getContractData = async () => {
            try {
                const contractId = parseInt(id ?? "0");
                const data = await getContractById(contractId);
                setContract(data);
            } catch (error) {
                console.error("Error fetching contract:", error);
            }
        };

        getContractData();
    }, [id]);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const result = reader.result as string;
                setImageBase64(result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSignatureChange = (base64: string) => {
        setSignatureBase64(base64);
    };

    const handleSave = async () => {
        try {
            if (!contract || !imageBase64.trim()) {
                console.error("Invalid contract or image data.");
                return;
            }
    
            const updatedContract: CreateContractValues = {
                name: contract.name,
                description: contract.description,
                receiverEmail: contract.receiverEmail,
                CreatedById: 2
            };
    
            const evidence: ContractEvidence = {
                id: 1,
                FileName: "",
                FilePath: "",
                FileExtension: "",
                CreatedAt: new Date(),
                UpdatedAt: new Date(),
                ContractId: contract.id,
                imageData: signatureBase64, 
                imageFile: imageBase64,
            };
    
            await updateContractById(contract.id, updatedContract, evidence);
        } catch (error) {
            console.error("Error saving contract:", error);
        }
    };    

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        handleSave();
    };

    useEffect(() => {

        if (signaturePadRef.current && !canvasRef.current) {
            const canvasReference = signaturePadRef.current.getCanvasRef();
            canvasRef.current = canvasReference;
        }
    }, [signaturePadRef]);

    return (
        <>
            <div className="px-4 flex justify-between items-center">
                <Link to="/contracts" className="text-white">
                    <FaArrowLeft />
                </Link>
                {contract && contract.status === 1 && (
                    <form onSubmit={handleSubmit}>
                        <button type="submit" className="text-white bg-blue-600 rounded-md border border-white px-2 py-2">
                            Guardar
                        </button>
                    </form>
                )}
            </div>

            <div className="flex h-full text-white mb-4">
                <div className="m-auto">Detalle Contrato</div>
            </div>

            <div className="h-screen bg-white">
                <div className="grid grid-cols-2 px-4 pt-2 mb-10">
                    <div className="py-4 border-b border-gray-300 text-gray-500">Titulo</div>
                    <div className="py-4 border-b border-gray-300 text-sm">{contract?.name}</div>
                    <div className="py-4 border-b border-gray-300 text-gray-500">Fecha de Actualizacion</div>
                    <div className="py-4 border-b border-gray-300 text-sm">
                        {contract ? new Date(contract.updatedAt).toLocaleDateString() : ''}
                    </div>
                    <div className="py-4 border-b border-gray-300 text-gray-500">Descripción</div>
                    <div className="py-4 border-b border-gray-300 text-sm">{contract?.description}</div>
                    <div className="border-b border-gray-300 py-4 flex float-right">
                        <div className="text-gray-500 flex justify-between"><FaReceipt/> Ref: </div>
                        <div className="px-2 text-m">{contract?.id}</div>
                    </div>
                    <div className="border-b border-gray-300 py-4 flex float-left">
                        <div className="text-gray-500 flex justify-between"><FaUser/> Usuario: </div>
                        <div className="px-2 text-sm">Juan Perez</div>
                    </div>
                </div>

                <div className="text-center">
                    {contract && contract.status === 1 && (
                        <div>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                style={{ display: "none" }}
                                id="imageInput"
                            />
                            <label htmlFor="imageInput" className="px-4 py-2 rounded-md bg-gray-600 text-white cursor-pointer">
                                Agregar imagen
                            </label>
                        </div>
                    )}
                    <div style={{ marginTop: "20px" }}>
                        {imageBase64 && (
                            <div style={{ textAlign: "center" }}>
                                <img
                                    src={imageBase64}
                                    alt="Preview"
                                    style={{ maxWidth: "300px", maxHeight: "300px", display: "block", margin: "0 auto" }}
                                />
                            </div>
                        )}
                    </div>

                    <div className="py-4">
                        <SignatureCanvas onImageChange={handleSignatureChange} />
                    </div>
                </div>
            </div>
        </>
    )
}