import { Link } from "react-router-dom"
import { ContractListItem } from "./ContractListItem"
import ContractListProps from "../../interfaces/props/ContractListProps"

export const ContractList = ({ items }: ContractListProps) => {
    return (
        <>
            {items.map((item) => (
                <Link key={item.id} to={`/contract-detail/${item.id}`}>
                    <ContractListItem item={item} />
                </Link>
            ))}
        </>
    )
}