//import { FC } from "react";
import {FaHome, FaRegClock, FaTimes, FaLaptop, FaUser} from 'react-icons/fa';
import { Link } from "react-router-dom";

interface SideMenuProps {
    isOpen: boolean;
    onClose: () => void;
}

export const SideMenu = ({ isOpen, onClose }: SideMenuProps) => {
    return (
        <div className={`fixed top-0 left-0 h-full w-64 bg-white overflow-y-auto transform transition-transform duration-200 ease-in-out ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}>
            <div className="bg-indigo-700 px-12 py-12 flex justify-center flex-col relative d-block">
                <div className="flex justify-center">
                    <div className="w-16 h-16 mt-8 mb-4 rounded-full overflow-hidden">
                        <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt="Workflow" />
                    </div>
                </div>

                <div className="text-white text-center">Bienvenido</div>
                <div className="text-white text-center">Juan Perez</div>
                <button onClick={onClose} className="focus:outline-none text-white absolute top-4 right-4">
                    <FaTimes />
                </button>
            </div>

            {/* Menu content goes here */}
            <div className="bg-gray-200 h-full flex flex-col justify-between">
                <div>
                    <Link onClick={onClose} to="/" className="flex items-center p-2 pt-4 text-gray-500 border-b border-white"><FaHome className="mr-2" /> Inicio</Link>
                    <Link onClick={onClose} to="/contracts" className="flex items-center p-2 text-gray-500 border-b border-white"><FaLaptop className="mr-2" /> Operaciones</Link>
                    <Link onClick={onClose} to="/contract-history" className="flex items-center p-2 text-gray-500 border-b border-white"><FaRegClock className="mr-2" /> Historial</Link>
                    <Link onClick={onClose} to="/" className="flex items-center p-2 text-gray-500 border-b border-white">Ajustes</Link>
                </div>
            </div>

            <div className="bg-gray-200">
                <Link onClick={onClose} to="/" className="flex items-center p-2 text-gray-500 py-2 border-t border-white"><FaUser className="mr-2" />Perfil</Link>
                <Link onClick={onClose} to="/" className="flex items-center p-2 text-gray-500 py-2 border-t border-white">Salir</Link>
            </div>
        </div>
    );
}; 