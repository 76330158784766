import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa'; // You can use any hamburger icon
import { SideMenu } from './SideMenu';

interface LayoutProps {
    children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const onClose = () => setIsOpen(false);

    return (
        <div className="bg-indigo-900 min-h-screen relative">
            <button onClick={() => setIsOpen(!isOpen)} className="p-4">
                <FaBars className="h-6 w-6 text-white" />
            </button>
            <SideMenu isOpen={isOpen} onClose={onClose}/>
            {children}
        </div>
    );
}