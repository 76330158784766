import {CreateContractForm} from "../components/forms/CreateContractForm";
export const CreateContract = () => {
    return (
        <div>
            <div className="text-white text-center py-4 pb-8">
                <div>Crear contrato</div>
            </div>
            <div className="h-screen bg-white">
                <CreateContractForm />
            </div>
        </div>
    );
};