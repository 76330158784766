import React, { useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { ContractCreationSchema } from '../../validationSchemas/ContractCreationSchema';
import { InputField } from '../forms/InputField';
import CreateContractValues from '../../interfaces/forms/CreateContractValues';
import { createContract } from '../../services/ContractsService';
import { Link } from 'react-router-dom';

export const CreateContractForm = () => {
    const initialValues: CreateContractValues = { name: '', description: '', receiverEmail: '', CreatedById: 2 };
    const [contractCreated, setContractCreated] = useState(false);
    const [redirectToHome, setRedirectToHome] = useState(false);

    const onContractCreate = async (values: CreateContractValues) => {
        try {
            const { message, data } = await createContract(values);
            setContractCreated(true); 

            setTimeout(() => {
                setContractCreated(false);
                setRedirectToHome(true); 
            }, 1000);
        } catch (error) {
            console.error("Error:", error);
        }
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={ContractCreationSchema}
                onSubmit={onContractCreate}>
                {({ isSubmitting }) => (
                    <Form className="text-center pt-12">
                        <InputField name="name" placeholder="Nombre del contrato*" type="text" />
                        <InputField name="receiverEmail" placeholder="Email del receptor*" type="text" />
                        <InputField name="description" placeholder="Descripcion del contrato*" type="text" />
                        {/* Uso de Link en el botón */}
                        <button
                            type="submit"
                            className="w-60 h-10 rounded-lg bg-blue-800 text-white"
                            disabled={isSubmitting}>
                            Crear Contrato
                        </button>
                    </Form>
                )}
            </Formik>
            {contractCreated && (
                <div className="text-center mt-4 text-green-600">
                    Contrato Creado
                </div>
            )}
            {redirectToHome && <Link to="/home" />}
        </>
    )
}
