import { LoginForm } from "../components/login/LoginForm";

export const Login = () => {
    return (
        <div className="bg-gradient-to-t from-blue-400 to-indigo-900 min-h-screen">
            <div className="flex justify-center">
                <div className="w-36 h-36 mt-8 mb-4 rounded-full overflow-hidden">
                    <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt="Workflow" />
                </div>
            </div>
            <div className="text-white text-center text-3xl">Bienvenido</div>
            
            <div className="mt-16 flex flex-row justify-center">
                <LoginForm />
            </div>

            <div>
                
            </div>
        </div>
    );
}