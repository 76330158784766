import { FaArchive } from "react-icons/fa";
import ContractListItemProps from "../../interfaces/props/ContractListItemProps";
import "./ContractListItem.css";

export const ContractListItem = ({ item }: ContractListItemProps) => {
    return (
        <div className="contract-item">
            <div className="contract-item-icon">
                <FaArchive />
            </div>
            <div className="contract-item-details">
                <div className="contract-item-id">ID: {item.id}</div>
                <div className="contract-item-name">{item.name}</div>
                <div className="contract-item-description">{item.description}</div>
            </div>
        </div>
    );
};