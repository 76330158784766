import {FaLaptop, FaRegClock} from 'react-icons/fa'
import { Link } from 'react-router-dom'
import logo from '../images/logo.png';

export const Home = () => {
    return (
        // <div className="bg-gradient-to-t from-blue-400 to-indigo-900 min-h-screen">
        <div className="bg-gradient-to-t from-blue-400 to-indigo-900 min-h-screen   ">
            <div className="flex justify-center">
                <div className="w-36 h-36 mt-8 mb-4 overflow-hidden">
                    <img src={logo} alt="Logo" style={{
                        width: '130%',
                        height: '100%'
                    }} />
                </div>
            </div>

            <div className="mt-16 px-16">
                <Link to="/contracts" className="bg-white p-5 flex rounded-md mt-4 justify-center">
                <div className="bg-blue-800 rounded-full p-4 text-center w-14 h-14 mr-4 flex items-center justify-center">
                    <FaLaptop className="text-white text-2xl" />
                </div>

                    <div className='flex flex-col justify-center'>
                        <div className='text-blue-800 text-center'>Operaciones</div>
                        <div className='text-gray-500 text-center' style={{fontSize: 9.5}}>Ver operaciones existentes</div>
                    </div>
                </Link>


                <Link to='/contract-history' className="bg-white p-5 flex rounded-md mt-4 justify-center">
                    <div className="bg-blue-800 rounded-full p-4 text-center w-14 h-14 mr-4 flex items-center justify-center">
                        <FaRegClock className="text-white" />
                    </div>
                    <div className='flex flex-col justify-center'>
                        <div className='text-blue-800 text-center'>Historial</div>
                        <div className='text-gray-500 text-center' style={{fontSize: 9.5}}>Ver historial de operaciones</div>
                    </div>
                </Link>
            </div>
        </div>
    )
}